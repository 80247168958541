import { GRAPHQL_ENDPOINT, SITE } from "@api/constants";
import getAccountSettings from "@api/queries/get-account-settings";

type AccountSettingsResponse = {
  data: {
    accountSettings: any;
  };
};

/**
 * Fetches
 */
const fetchAccountSettings = async (siteId: string): Promise<{ accountSettings: any }> => {
  const body = {
    query: getAccountSettings,
    variables: { siteId: siteId },
  };

  const response = await fetch(GRAPHQL_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CORE-SITE": SITE,
    },
    body: JSON.stringify(body),
  });

  const responseBody = (await response.json()) as AccountSettingsResponse;
  return responseBody?.data;
};

export default fetchAccountSettings;
