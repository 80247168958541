import fetchAccountSettings from "@api/operations/fetch-account-settings";
import Script from "next/script";
import React, { useEffect, useState } from "react";

interface GoogleTagManagerProps {
  site: string;
}

const getPixelAttribute = (attributes: { code: string; value: string }[], code: string) => {
  let returnAttribute = attributes.find((a) => a.code === code);

  if (returnAttribute?.value === undefined) {
    return "";
  }

  return returnAttribute.value ? returnAttribute.value : "";
};

export const GoogleTagManager: React.FC<GoogleTagManagerProps> = ({ site }) => {
  const [googleAnalytics, setGoogleAnalytics] = useState<string>("");

  useEffect(() => {
    fetchAccountSettings(site).then((data) => {
      const attributes = data.accountSettings?.attributes ?? [];
      setGoogleAnalytics(getPixelAttribute(attributes, "googleAnalytics"));
    });
  });

  return (
    <>
      {googleAnalytics && (
        <>
          <Script
            id="google-tag-manager"
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${googleAnalytics}');`,
            }}
          />
        </>
      )}
    </>
  );
};
