import fetchAllProducts from "@api/operations/fetch-all-products";
import Product from "@api/types/product";
import { GetStaticPropsContext, InferGetStaticPropsType } from "next";
import { NextSeo } from "next-seo";

import Container from "@components/Container";
import ImportantNotice from "@components/ImportantNotice";
import MainLayout from "@components/MainLayout";
import ProductCardGrid from "@components/ProductCardGrid";
import Spacer from "@components/Spacer";

export async function getStaticProps(context: GetStaticPropsContext) {
  const products: Product[] = await fetchAllProducts();

  return {
    props: {
      products,
    },
    revalidate: 300, // 5 minutes in seconds
  };
}

export type IndexPropsType = InferGetStaticPropsType<typeof getStaticProps>;

export default function Home({ products }: IndexPropsType) {
  return (
    <>
      <NextSeo
        title="Bobcat Online Training"
        description="Bobcat Company offers a variety of training and safety resources for owners, renters and operators. The options vary by machine or attachment."
      />
      <Spacer size="md" />
      <Container>
        <ImportantNotice />
        <ProductCardGrid products={products} />
      </Container>
    </>
  );
}

Home.Layout = MainLayout;
