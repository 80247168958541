const getAccountSettings = /* GraphQL */ `
  query accountSettings($siteId: String!) {
    accountSettings(siteId: $siteId) {
      accountId
      attributes {
        code
        value
      }
    }
  }
`;

export default getAccountSettings;
