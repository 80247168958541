import Link from "next/link";
import { FC, PropsWithChildren } from "react";

export type ButtonProps = {
  theme?: "primary" | "secondary";
  href: string;
  width?: number | string | undefined;
};

const Button: FC<PropsWithChildren<ButtonProps>> = ({
  theme = "primary",
  href,
  width = "100%",
  children,
}) => {
  return (
    <>
      <Link href={href}>
        <div className="Button">{children}</div>
      </Link>
      {/* language=CSS */}
      <style jsx>
        {`
          .Button {
            width: ${width};
            text-align: center;
            background-color: ${theme == "primary" ? "rgb(255, 54, 0)" : "rgb(45, 45, 45)"};
            color: white;
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;
            padding: 16px 10px;
            cursor: pointer;
          }

          .Button:hover {
            background-color: ${theme == "primary" ? "rgb(236, 31, 0)" : "rgb(45, 45, 45)"};
          }
        `}
      </style>
    </>
  );
};

export default Button;
