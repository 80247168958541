type ProductRedirects = {
  [key: string]: string;
};

const redirects: ProductRedirects = {
  CTOT: "https://shop.bobcat.com/compact-tractor-online-operator-training-course-7499027",
  TWOCOURSE: "https://shop.bobcat.com/multi-course-online-operator-training-7499022",
  RRC: "https://shop.bobcat.com/loader-radio-remote-control-online-operator-training-course-7499025",
  CEOT: "https://shop.bobcat.com/compact-excavator-online-operator-training-course-7499024",
  SSLOT: "https://shop.bobcat.com/skid-steer-loader-online-operator-training-course-7499023",
  TCUWM: "https://shop.bobcat.com/toolcat-utility-work-machine-online-operator-training-course-7499026",
  AWL: "https://shop.bobcat.com/articulated-wheel-loader-online-operator-training-course-46895556",
  TELE: "https://shop.bobcat.com/telehandler-online-operator-training-course-46929263",
};

export function getProductRedirect(key: string): string {
  return redirects[key];
}

export function hasProductRedirect(key: string): boolean {
  return !!getProductRedirect(key);
}
