import React, { FC, PropsWithChildren } from "react";

import Container from "@components/Container";
import Footer from "@components/Footer";
import { GoogleTagManager } from "@components/GoogleTagManager";
import Header from "@components/Header";
import Spacer from "@components/Spacer";

const MainLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <GoogleTagManager site="BOBL" />
      <Spacer size="sm" />
      <div className="break">
        <Container>
          <Header />
        </Container>
        <Spacer size="sm" />
      </div>
      {children}
      <Spacer size="lg" />
      <Footer />
      {/* language=CSS */}
      <style jsx>
        {`
          .break {
            border-bottom: 1px solid rgb(194, 194, 194);
          }
        `}
      </style>
    </>
  );
};

export default MainLayout;
