import React, { FC } from "react";

import Spacer from "@components/Spacer";

const ImportantNotice: FC = () => {
  return (
    <div className="important-notice">
      <h1>Important Notice</h1>
      <p>
        We have made improvements to the online course experience. Courses are now available at{" "}
        <a href="https://shop.bobcat.com" target="_blank">
          shop.bobcat.com
        </a>
        . If you have any questions, please email us at <a href="mailto:shop@bobcat.com">shop@bobcat.com</a>{" "}
        or call <a href="tel:1-844-926-2228">1-844-926-2228</a>.
      </p>
      <Spacer size="md" />
      {/* language=CSS */}
      <style jsx>
        {`
          .important-notice {
            font-family: Lato, Regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
          }

          .important-notice a {
            color: rgb(255, 54, 0);
          }
        `}
      </style>
    </div>
  );
};

export default ImportantNotice;
